.header {
    background:#FFFFFF;
    color: white;
    display: flex;
    position: sticky;
    top: 0;
    left: 17%; 
    width: 83%;
    padding: 20px;
    z-index: 100;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_left_img{
    padding-top: 10px;
    padding-bottom: 15px;
    object-fit: cover;
}
.header_right{
    display: flex;
    gap: 20px;
    align-items: center;
}
.header_right_left{
    display: flex;
    gap: 10px;
    align-items: center;
}
.parent_icons{
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.parent_icons span{
    color: #646464;
    font-size: 15px;
}
.header_right_right{
    display: flex;
    gap:10px;
    align-items: center;
    padding-right: 10px;
}
.header_right_right_child2{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.picture_admin{
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
}
.information_admin{
    display: flex;
    gap: 3px;
    align-items: center;
    cursor: pointer;
}
.down{
     width:17px;
    height:17px;
    border-radius: 50%; 
    border: 0.2px solid #5C5C5C;
     padding:5px; 
}
.name_admin{
    color: #404040;
    font-size: 15px;
    font-weight: 700;
}
.role_admin{
    color: #565656;
    font-size:14px;
}
.change_language{
    display:flex;
    flex-direction:column;
    gap: 10px;
    background:white;
    padding-top:10px;
    padding-bottom:10px;
    border-radius:10px;
    border: none;
    position: absolute;
    top: 72px;
    right: 200px;
}
.change_language_span{
    font-size: 14px;
    padding-bottom: 5px;
    padding-right: 40px;
    padding-left: 10px;
    color: #404040;
    border-bottom: 0.2px solid #979797;
}
.sous_change_language{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 40px;
    padding-left: 10px;
}
.sous_change_language div{
    display: flex;
    gap:10px;
    align-items: center;
    cursor: pointer;
}
.sous_change_language div span {
    font-size: 14px;
    color: #404040;
}
.language_img{
   object-fit:cover;
}
.comptes{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: absolute;
    top: 72px;
    background: white;
    right: 10px;
    cursor: pointer;
}
.sous_comptes{
    display: flex;
    gap: 15px;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 30px;
    border-bottom: 0.5px solid #979797;
}
.sous_comptes img{
    object-fit: cover;
}
.sous_comptes span{
    color: #404040;
    font-size:14px;
}
.sous_comptes1{
    display: flex;
    gap: 15px;
    align-items: center;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 10px;
    padding-right: 30px;
}
.sous_comptes1 img{
    object-fit: cover;
}
.sous_comptes1 span{
    color: #404040;
    font-size:14px;
}
.tiretsBottom{
    display: flex;
    gap: 20px;
    border-bottom: 2px solid #F4F5F7;
    
}
.infosComptes{
color: #FFB67C;
border-bottom: 2px solid #FFB67C;
font-size: 13px;
transform: translate3d(0,2px,0);
}
.infosSecurite{
    font-size: 13px;
    color:#718096;
}
.photoInfos{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}
.parent_button_tribunal1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}