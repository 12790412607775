.connect{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:url("../../../assets/icons/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10000;
}
.form_connect{
    margin: auto;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap:30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
    background: #fff;
    width:450px;
    border-radius: 10px;
}
.form_connect_title{
    margin: auto;
    padding-top: 30px;
}
.children_form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: relative;
}
.children_form label{
    text-align: left;
    color: #718096;
    font-size: 14px;
}
.children_form input{
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    border: 1px solid #20222433;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
    background: #FAFAFA;
    outline: none;
    color: #4A5568;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
}

.children_form input::placeholder{
   font-size: 14px !important;
   font-weight: 500;
}
.button_tribunal_connect{
    color:#fff;
    background:#FFB67C;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight:500;
    width: 100%;
    transform: translate3d(0,10px,0);
}
.error_message {
    color: red;
    font-size: 0.8em;
    position: absolute;
    bottom: -25px;
  }

  
  .loading_content {
    color:#fff;
    background:#E94E1B;
    border: none;
    border-radius: 10px;
    outline: none;
    width: 100%;
    transform: translate3d(0,10px,0);
  }
  .loading{
     display: flex;
    align-items: center; 
     gap: 10px;
    font-weight:500;
    outline: none; 
    padding-top: 10px;
    padding-bottom: 10px;
    transform:translate3d(150px,0,0) ;
  }
  .loading_span{
    font-size: 14px !important;
  }
  
  .spinner {
    width: 15px;
    height: 15px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .eyes_connexion{
    position: absolute;
    bottom: 12px;
    right: 15px;
    cursor: pointer;
  }
  