
.pagination {
  display: flex;
  align-items: center;
  gap: 5px;
  transform: translate3d(0,-10px,0);
}

.pagination-arrow {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.pagination-number {
  background: none;
  border: none;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 7px;
  font-size: 1rem;
  color: #333;
}

.pagination-number.active {
  background-color: #ffab7f;
  color: #fff;
  font-weight: bold;
}

/* .pagination-arrow[disabled] {
  display: none;
} */