.main_accueil{
    display: flex;
    flex-direction: column;
    gap:40px;
    width: 100%;
    padding-bottom: 40px !important;
}
.parent_details{
    /* display:flex; */
    width: 100%;
    gap:20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}