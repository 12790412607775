.parent_header{
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
}
.title_main{
    color: #202224;
    font-size: 23px;
    font-weight: 600;
    width:50%;
}
.search_input{
    position: relative;
    width:50%;
}
.search_input input{
   padding: 10px; 
   padding-top: 14px;
   padding-bottom: 14px;
   padding-left: 40px;
   border-radius: 10px;
    border:1px solid #D5D5D5; 
   background: #fff;
   width: 100%;
   outline: none;
}
.search_input input::placeholder{
    color:grey;
    font-size:15px !important;
}
.search_information{
    position: absolute;
    top: 13px;
    left: 15px;
}