.header_document_details {
    display: flex;
    gap: 25px;
    align-items: center;
    cursor:pointer !important;
}

.header_document_details_h2 {
    color: #202224;
    font-size: 22px;
    font-weight: 600;
}

.button_status_document {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    border: none;
    border-radius: 5px;
    background: #FFB67C;
    color: white;
    font-size: 13px;
}

.details_documents {
    display: flex;
    flex-direction: column;
    gap: 25px;
    transform: translate3d(0, -30px, 0);
}

.details_documents_header {
    display: flex;
    flex-direction: column;
}
.button_status_document1{
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    border: none;
    border-radius: 5px;
    background: #037847;
    color: white;
    font-size: 13px;
}
.option_chips_name{
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    border: none;
    background:transparent;
    outline: none;
    color: #4A5568;
    font-size:15px;
    cursor:pointer;
    font-weight:500;
    display:flex;
    gap:10px;
    align-items:center;
    flex-wrap:wrap;
    width: 100%;
    position: absolute;
    bottom:-50px;
}
.option_chips_name2{
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    border: none;
    background:transparent;
    outline: none;
    color: #4A5568;
    font-size:15px;
    cursor:pointer;
    font-weight:500;
    display:flex;
    gap:10px;
    align-items:center;
    flex-wrap:wrap;
    width: 100%;
    position: absolute;
    bottom:-65px !important;
}
.details_documents_header_h3 {
    color: #202224;
    font-size: 18px;
    font-weight: 600;

}

.details_documents_header_span {
    color: var(--Grays-Gray, #8E8E93);
    font-size: 14px;
    font-weight: 500;
    transform: translate3d(0, -10px, 0) !important;
}

.parent_form_children {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    padding-top: 30px;
}

.textarea_document {
    padding-top: 50px !important;
}

.details_pdf {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sous_details_file {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #757575;
    background: #FFFFFF;
    width: 250px;
    font-size: 14px;
    color: #202224CC;
}

.transcription {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.transcription label {
    text-align: left;
    color: black;
    font-size: 16px;
    font-weight: 550;
}

.sous_description_transcription {
    border: 1px solid #3E3E3E4D;
    border-radius: 5px;
    padding-top: 5px;
    background: white;
}

.sous_description_img_transcription {
    display: flex;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #C5C5C5;
    padding-left: 20px;
}

.textarea_transcription {
    font-size: 14px;
    border: none;
    outline: none;
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 25px;
    height: 250px !important;
    color: #202224;
    overflow-y: auto;
    width: 100% !important;
    resize: none;
}

.textarea_transcription ::placeholder {
    color: #909090;
    font-size: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.img_profession {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.link {
    width: 18px !important;
    height: 18px !important;
    transform: translate3d(0, -5px, 0);
}

.upload {
    width: 18px !important;
    height: 18px !important;
    transform: translate3d(0, -4px, 0);
    cursor: pointer;
}

.list {
    width: 18px !important;
    height: 18px !important;
    transform: translate3d(0, -5px, 0);
    cursor: pointer;
}

.favoris_document {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sous_favoris_document{
    display: flex;
    gap:10px;
    align-items: center;
    cursor: pointer;
}
.sous_favoris_document span{
 color: #202224;
font-size:18px;
font-weight: 600;
}
.sous_favoris_parent_button{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sous_favoris_button{
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}
.sous_favoris_button_1{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
    color:white;
    background: #4AB635;
    font-size: 14px;
    cursor: pointer;
}
.sous_favoris_button_2{
    background: #CC0000;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
    color:white;
    font-size: 14px;
}