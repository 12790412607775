
.children_form_favoris{
    display: flex !important;
    gap: 10px;
    align-items: center !important;
    cursor: pointer;
}
 .imageFile{
    object-fit: cover !important;
    width: 100px !important;
    height: 100px !important;
} 
.parent_check_images{
    display: flex;
    align-items: center;
    gap:15px;
}
.text{
   text-align: center !important;
}
.text1{
    text-align: center !important;
    transform: translate3d(0,-15px,0); 
}
.parent_custom_file_input{
    width:50%;
    border: 1px solid darkgray;
    padding:20px;
    border-radius:5px;
    cursor:pointer;
}


.parent_custom_file_input img{
    object-fit: cover;
    width: 30px;
    height: 30px;
    display:flex;
    margin:auto;
    cursor: pointer !important;
}
 .custom-file-input input[type="file"]{
    position:absolute;
    font-size:100px;
    right: 0;
    top: 0;
    opacity: 0;
    cursor: pointer !important;
    width:300px;
 } 
 .document_span{
  margin-left: 50px !important;
 }
 .custom-file-input{
    color:#191D23 !important;
    font-weight: 500;
    cursor: pointer !important;
 }
 
.children_form_1{
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    border: 1px solid #20222433;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
    background: #FAFAFA;
    outline: none;
    color: #4A5568;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popup_content{
    background:white;
    width: 100%;
    min-height: 200vh;
    /* margin: auto; */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    border-radius: 5px;
    z-index: 10000 !important;
    position:absolute;
    top:0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 20px !important;
    padding-bottom: 20px; 
}
.image-gallery{
    display:flex;
    flex-wrap: wrap; 
      gap: 20px; 
      padding-top: 20px;
      cursor: pointer;
}
.image-preview{
     width:calc(100% / 3 - (40px / 3)); 
     height: 150px; 
     position: relative !important;
}
.image-preview_pdf{
    width:100%;

}
/* .check_all_document{
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.parent_circle_position{
  width: 2.5em;
  height: 1.15em;
  background: #616772;
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 2px !important;
  position: relative;
  cursor: pointer;
}
.child_circle_position{
  border-radius: 50%;
  background:white;
  width: 0.9em;
  height: 0.9em;
  position: absolute;
  left: 4px;
  color: transparent;
  cursor: pointer;
}
.displayDocuments{
  font-size: 14px;
  font-weight: 550;
}
.select_all_favoris-document{
  display: flex;
  gap: 5px;
  align-items: center;
} */
.option_chips{
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    border: none;
    background:transparent;
    outline: none;
    color: #4A5568;
    font-size:15px;
    cursor:pointer;
    font-weight:500;
    display:flex;
    gap:10px;
    align-items:center;
    flex-wrap:wrap;
    width: 100%;
}
.chip{
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
     padding-left: 5px; 
     padding-right: 5px; 
    border-radius: 15px;
    border:1px solid gray ;
    background: white;
}

.remove_img{
    position: absolute !important;
    top: 10px;
    right: 5px;
    /* width: 25px !important;
    height: 15px !important; */
    /* object-fit: cover; */
}
.image_preview_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:5px !important;
}
.image_preview_pdf{
    width: 100% !important;
    height: 100%;
      object-fit: cover;
      border-radius:5px !important;
}
/*****date-picker********/
.react-datepicker-popper{
    background:#FAFAFA; 
    border-radius:5px;
   transform: translate3d(0,4.9em,0) !important;
  }
  
  .react-datepicker-wrapper{
     display:inline-block; 
    border:none;
    z-index: 1;
    background:transparent !important;
  }
  .react-datepicker-wrapper input{
 border: 0.1em solid #DDDDDD;
    outline:none;
    padding:15px;
    border-radius: 15px;
    background-color: #FAFAFA; 
    width: 100%;  
 }
  
  .react-datepicker {
    font-family: "Arial", sans-serif;
    background: #F5F5F5;
    color: black;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }
  
  .react-datepicker__triangle {
     opacity: 0;
  }
  
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: white;
   
  }
  
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    border-top-color: white;
    
  }
  
  .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
     border-top-left-radius: 0px;
    border-top-right-radius: 0px !important; 
    position: relative; 
  }
  
  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select {
    display: inline-block;
    
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color:#000000;
    font-weight:700;
    font-size: 1em;
    transform: translate3d(0,3px,0);
    
  }
   .react-datepicker__day--selected {
  background-color: #FFB67C !important; 
  color: white !important; 
} 

 .react-datepicker__day--keyboard-selected {
  background: #FFB67C !important;
  color: white !important; 
  font-weight: 600;
} 

  
  .react-datepicker__navigation {
    top: 8px !important;
    line-height: 1.7rem;
    position: absolute;
    cursor: pointer;
    border: none;
    z-index: 1;
    background:transparent;
    
  }
  
  .react-datepicker__navigation--previous {
    left: 10px;
  }
  
  .react-datepicker__navigation--next {
    right: 10px;
  }
  
  .react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
    
  }
  
  .react-datepicker__navigation-icon--previous {
    right: 2px;
  }
  
  .react-datepicker__navigation-icon--next {
    left: 2px;
    
  }
  
  .react-datepicker__month-container {
    float: left;
    
    
  }
  
  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    
    
  }
  
  .react-datepicker__week {
    white-space: nowrap; 
  }
  .react-datepicker__week div{
    color:black;
    font-weight: bold;
  }
  .react-datepicker__week div:hover{
    background-color:#aeaeae !important;
  }
  
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2rem;
    line-height: 2.5rem !important;
    margin: 0.166rem;
    display: inline-block;
    text-align: center;
    cursor: pointer; 
  }
  .react-datepicker__close-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .react-datepicker__close-icon::after {
    content: '×';
    font-size: 1.2em;
    color: white; 
    background:#DDDDDD !important;
    transform: translate3d(-10px,0,0) !important;
  }
  .active-filter{
    background-color: #DDDDDD !important; 
  }
  
  
 
  

  
 
  

  

  
  
  
 
  
  
  
  
 
  
  
  
 

  
  

