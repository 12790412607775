
 @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap'); 

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; 
    font-family:"Manrope";
    scroll-behavior: smooth;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.App {
    position: relative;
    height: 100vh;
    overflow: hidden;
}
:root {
    --color-primary: #0084FA;
    --color-secondary: #24D26D;
  }
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  
  ::-webkit-scrollbar-track {
    background: #E6D4F8 !important;
  }
  
  ::-webkit-scrollbar-thumb {
    background:var(--color-secondary);
  }


.main {
    margin-left: 17%; 
    width: 83%;
    height: 100vh; 
    overflow-y: auto;
     background: #FAFAFA; 
    padding-left: 40px;
    padding-right: 40px;
    padding-top:25px;
    padding-bottom: 70px !important;
}

