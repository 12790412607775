.graph{
    background: white;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 30px;
    padding-bottom: 40px;
    border-radius: 20px;
}
.graph_h1{
    padding-left: 1.2em;
  color: "#202224";
  font-size: 22px;
  font-weight: 500;
  transform: translate3d(0,-10px,0);
}