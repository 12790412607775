#snackbar {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #E94E1B;
    color: white;
    padding: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: scale3d(0, 0, 0);
    transition: transform 0.3s ease-in-out;
  }
  
  #snackbar.snackbar-visible {
    transform: scale3d(1, 1, 1);
  }
  
  #snackbarImg {
    margin-right: 10px;
  }
  
  #actionSnackbar {
    cursor: pointer;
    margin-left: 15px;
    color: red;
  }