.user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
 .user-table th{
    padding: 10px; 
    text-align: left;
    border-bottom: 1px solid #EAECF0; 
    padding-left: 25px;
    padding-right: 25px;
    background: #FCFCFD;
    font-size: 14px;
    color: #3F4042;
  font-size: 550;
  }
  .user-table_th{
    padding: 10px; 
  text-align: left;
  border-bottom: 1px solid #EAECF0; 
  padding-left: 25px;
  padding-right: 25px;
  background: #FCFCFD;
  font-size:0.75em !important;
  color: #3F4042;
font-size: 550;
white-space: nowrap;       
    overflow: hidden;            
    text-overflow: ellipsis;
}
  .user-table td{
    padding: 10px; 
    text-align:left;
    border-bottom: 1px solid #EAECF0; 
    background: white;
    padding-left: 25px;
    padding-right: 25px;
    color: #667085;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap !important; 
    overflow: hidden !important;    
    text-overflow: ellipsis !important; 
    max-width:135px; 
    position: relative;

  }
  /* .ellipsis{
    white-space: nowrap !important; 
    overflow: hidden !important;    
    text-overflow: ellipsis !important; 
    max-width:135px; 
    position: relative;
  } */
  
  /* .user-table td:nth-last-child(1){
    display: flex !important;
    gap:10px !important;
    align-items: center !important; 
  } */
   .user-table_td{
    padding: 10px; 
    text-align:left;
    border-bottom: 1px solid #EAECF0; 
    background: white;
    padding-left: 25px;
    padding-right: 25px;
    color: #667085;
    font-weight: 500;
    font-size: 0.72em !important;
    white-space: nowrap;       
    overflow: hidden;            
    text-overflow: ellipsis;
  } 
  .status_valide{
    background: #ECFDF3 !important;
    color: #037847 !important;
    text-align: center !important;
  }
  .status_en_attente{
    background: #FFE8D6 !important;
    color:#FFB67C !important;

  }
  .star1{
    opacity:1;
    object-fit: cover;
     width: 15px;
    height: 15px; 
  } 
   .star{
    opacity: 0;
    object-fit: cover;
     width: 15px;
    height: 15px; 
  } 
  .rightCategorie{
    cursor: pointer;
  }
   .control_img{
    display:flex;
    align-items:center;
    gap:10px;
    cursor: pointer;
  } 
  .control_img_document{
    display: flex;
    align-items: center;
    gap:5px;
    cursor: pointer;
  

  }
  .control_img_document img{
    width: 15px;
    height: 15px;
    object-fit: cover;
  }
  .remover{
    width: 14px;
    height: 14px;
    object-fit: cover;
    transform: translate3d(10px,-3px,0);
  }
  
  @keyframes fadeAndSlide {
    0% {
      opacity: 0;
      transform: translateX(100px); 
    }
    100% {
      opacity: 1; 
      transform: translateX(0); 
    }
    
  }
  

.update_sous_categorie_wrapper {
  width: 100%;
  padding: 10px;
  background-color: #f8f9fa;
  opacity: 0;
  transform: translateX(100px);
  transition: none;
}
.update_sous_categorie_wrapper.expanded {
  animation: fadeAndSlide 1s ease-in-out forwards;
}


.update_sous_categorie_text1 {
  display: flex;
  justify-content: space-between;
  width: 100%; 
  padding: 10px 25px; 
  background-color: white;
  border-bottom: 1px solid #EAECF0;
  color: #667085;
}
.display_sous_categories{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EAECF0; 
  color: #667085;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.display_sous_categories :nth-last-child(){
  border-bottom:none; 
}
.contentEditables{
  color:#718096;
  border:none;
  outline: none;
}
.remove_sous_categories{
cursor: pointer;
object-fit: cover;
width: 15px;
height: 15px;
}
.no-results{
  text-align: center !important;
  color: #EA0234 !important;
  font-weight: 550 !important;

}
.error_message_connexion{
  color: #EA0234 !important;
  margin: auto;
  font-size:15px;
  font-weight: 550 !important;
}
.masqueOpenModal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4000;
  display: flex;
  justify-content: center;
  background:black;
  opacity: 0.8;
}

.openModal{
  background: white;
  width: 35%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  border-radius: 5px;
  z-index: 9000 !important;
  position:absolute;
  top:50px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 20px !important;
  padding-bottom: 40px;
}
.parent_closeRemove{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parent_title{
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.parent_title img{
  margin: auto;
  display: flex;
}
.button_remove{
  background: #FFB67C;
  color: white;
  padding-top: 13px;
  padding-bottom: 13px;
  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 0px 4px 0px #00000026;
   width:100%; 
  text-align: center;
  padding-right:55px;
  padding-left: 55px;
  cursor: pointer;
  font-size:17px;
}
.title_remove{
    color: #202224;
    font-size: 20px;
    font-weight: 600;
}
.check_all_document{
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.parent_circle_position{
  width: 2.5em;
  height: 1.15em;
  background: #616772;
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 2px !important;
  position: relative;
  cursor: pointer;
}
.child_circle_position{
  border-radius: 50%;
  background:white;
  width: 0.9em;
  height: 0.9em;
  position: absolute;
  left: 4px;
  color: transparent;
  cursor: pointer;
}

.displayDocuments{
  font-size: 14px;
  font-weight: 550;
}
.select_all_favoris-document{
  display: flex;
  gap: 5px;
  align-items: center;
}
.bloc_pagination{
  padding-top: 2em;
}
 
