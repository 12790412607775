.parent_stat{
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 15px;
    border: none;
    border-radius:15px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #00000033;
      /* width:calc((100% / 4) - 80px / 4);   */
}
.parent_stat_1{
    display: flex;
    justify-content: space-between;
}
.parent_text{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.parent_stat_2{
    display: flex;
    gap:2px;
    align-items: center; 
}
.iconegraphe{
    object-fit: cover;
}

.parent_text_2{
    font-size: 22px;
    font-weight: 700;
}
.parent_text_1{
    font-size: 15px;
    color: #202224;
    font-weight: 550;
}
.parent_created{
    display:flex;
    gap: 3px;
    align-items: center;
    padding-right: 10px;
}
.stat_img{
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.stat_pourcent{
    color: #43A430;
    font-weight:550;
    font-size: 15px;
}
.date{
    font-size: 13px;
    color: #202224;
    font-weight: 600;
}