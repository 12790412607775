.all_parent_question{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.parent_question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F2F2F7;
    cursor: pointer;
}
.check_question{
    display: flex;
    align-items: center;
    gap: 10px;
    padding:15px;
}
.parent_question_description{
    padding: 15px;
    border-bottom: 1px solid #F2F2F7;
    border-left: 1px solid #F2F2F7;
    border-right: 1px solid #F2F2F7;
    transform: translate3d(0,0,0);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: gray;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}
.parent_question_description_span{
  width:70% ;  
}
.img_right{
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #F2F2F7;
}
.check_question_span{
    font-size:16px !important;
    font-weight:550 !important;
}
.seed_remove{
    display:flex;
    align-items:center;
    gap: 5px;
    cursor: pointer;
}
.sous_update_faq{
    display:flex;
    flex-direction:column;
    gap: 10px;
}
.update_faq{
    display: flex;
    flex-direction: column;
    gap: 15px !important;
}
.fr{
    font-weight:500;
    font-size: 15px;
}
.textarea{
    resize: none;
}