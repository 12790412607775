.all_filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.popup_wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4000;
    display: flex;
    justify-content: center;
    overflow-y: auto; 
  } 

#masque{
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:black;
    opacity:0.8;
     height: 100vh !important; 
    overflow-y: auto !important; 
    z-index: 4000;
    
  }
 
  #answer_form{
     background: white;
      width: 40%;
        margin: auto !important;  
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
      border-radius: 5px;
      z-index: 9000 !important;
       position:relative;
      top:30px; 
        left:0; 
       right: 0;  
      display: flex;
      flex-direction: column;
      gap: 20px !important;
      padding-bottom: 20px; 
      z-index: 7000; 
    }
    #answer_form_judiciaire{
        background: white;
      width: 60%;
      margin: auto;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
      border-radius: 5px;
      z-index: 9000 !important;
      position:absolute;
      top:50px;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: 20px !important;
      padding-bottom: 20px; 
    }
    .parent_answer_form_judiciaire{
        display: flex;
         gap: 40px; 
        width: 100%;
    }
    .answer_form_judiciaire1{
    display: flex;
      flex-direction: column;
      gap: 20px !important;
      width: 50%;
    }
    .answer_form_judiciaire2{
        display: flex;
      flex-direction: column;
      gap: 20px !important;
      position: relative !important;
      width: 50%;
    }
 
    .parent_button_tribunal_judiciare{
        position: absolute !important;
        right: 30px;
        bottom: 25px;
        display: flex;
        gap:15px;
        align-items:center;
        width:calc(50% - 40px);
    }
.parent_filter{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 550;
    cursor: pointer;
}
.parent_filter_countries{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 45%;
    font-weight: 550;
    cursor: pointer;
}
.parent_filter_document_reader{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 45%;
    font-weight: 550;
    cursor: pointer;
}
.parent_filter_document{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 70%;
    font-weight: 550;
    cursor: pointer;
}
.parent_filter_judiciares{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 50%;
    font-weight: 550;
    cursor: pointer;
}
.parent_filter_judiciaresReader{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 30%;
    font-weight: 550;
    cursor: pointer;
}
.sous_filter1{
    text-align: center;
    padding-left: 20px;
    padding-right: 30px;  
    color: #202224CC;
    border-right: 1px solid #D5D5D5;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 700;
}
.sous_filter_Document{
    text-align: center;
    padding-left: 15px;
    padding-right: 25px;  
    color: #202224CC;
    border-right: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    font-weight: 700;
}
.flex_filter{
    display: flex;
    flex-direction: column;
     width: 50%; 
     gap: 7px;
}
.select_parent_filter{
    display: flex;
    width: 100%;
}
.select_parent_filter1{
    width: 50%;
    border-radius: 10px;
    position: relative;   
}
.select_input{
    padding-left: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #EAECF0;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
     background: #FAFAFA; 
    outline: none;
    color: #4A5568;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
}
.close_select{
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
}
.option{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translate3d(0,64px,0);
    border: 1px solid #EAECF0;
    z-index: 10000;
    max-height:200px !important;
    overflow-y: auto;
    background: #667085;
    position: absolute;
    top:-23px;
    background: white;
}
.optionCity{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #EAECF0;
    z-index: 10000;
    max-height:200px !important;
    overflow-y: auto;
    background: #667085;
    position: absolute;
    top:80px;
    background: white;

}
.optionRole{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 200px;
    border: 1px solid #EAECF0;
    z-index: 10000;
    max-height:200px !important;
    background:white;
    position: absolute;
    top:40px;
    left: 0;
}
.optionRole span{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    text-align: left;
    border-bottom: 1px solid #EAECF0;
     font-size: 13px;
     transition: 1s;
     cursor: pointer;
}
.optionRole span:hover{
    background:grey;
    color: white;  
}
.optionGraph{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
     /* transform: translate3d(0,64px,0);  */
    border: 1px solid #EAECF0;
    z-index: 10000;
    max-height:200px !important;
    overflow-y: auto;
    background: #667085;
    position: absolute;
    top:2.5em;
    background: white;

}
.optionCity span{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    text-align: left;
    border-bottom: 1px solid #EAECF0;
     font-size: 13px;
     transition: 1s;
     cursor: pointer;
}
.optionGraph span{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    text-align: left;
    border-bottom: 1px solid #EAECF0;
     font-size: 13px;
     transition: 1s;
     cursor: pointer;
}
.optionCity span:hover{
    background:grey;
    color: white;  
}
.optionGraph span:hover{
    background:#e8ebf7;
    color:#2b3034;  
}
.option span{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    text-align: left;
    border-bottom: 1px solid #EAECF0;
     font-size: 13px;
     transition: 1s;
     cursor: pointer;  
 }
 .option span:hover{
    background:grey;
    color: white;
 }
.select_parent_filter2{
    width: 50%;
    border-radius: 10px;
    position: relative;
}
 .checkbox_filter{
    display: flex;
    gap: 30px;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    border-right: 1px solid #D5D5D5;
    padding-top: 15px;
    padding-bottom: 15px;  
    cursor: pointer; 
    position: relative;
}
 .checkbox_filter_document{
    display: flex;
    gap:15px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    border-right: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 10px; 
}
.checkbox_filter_document_reader{
    display: flex;
    gap:15px;
    align-items: center;
    /* padding-left: 20px;
    padding-right: 20px; */
    justify-content:"center";
    align-items: center;
    border-right: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 10px; 
}
.checkbox_filter_judiciaires{
    display: flex;
    gap:15px;
     padding-left: 15px;
    padding-right: 15px; 
    align-items: center;
    border-right: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 10px; 
}
.checkbox_filter_judiciairesRole{
    display: flex;
    gap:15px;
     padding-left: 15px;
    padding-right: 15px; 
    align-items: center;
    border-right: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 10px; 
    position: relative !important;
}
.checkbox_filter_document_span{
    color: #202224CC !important;
    font-size: 13px !important;
    font-weight: 700 !important; 
}
.checkbox_filter_judiciaires_span{
    color: #202224CC !important;
    font-size: 13px !important;
    font-weight: 700 !important;
}
.checkbox_filter_span{
    color: #202224CC !important;
    font-size: 14px !important;
    font-weight: 700 !important;   
}
.sous_filter2{
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    padding-left: 25px;
    padding-right: 20px;
    color: #202224CC;
    font-size: 14px;
    font-weight: 700;
} 
.sous_filter2_document{
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    padding-left: 20px;
    padding-right: 15px;
    color: #202224CC;
    font-size: 13px;
    font-weight: 700;
}
.children_form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: relative;
}
.children_graph{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100px;
    position: relative;
}


.document{
    position: absolute;
    bottom:18px;
    right: 20px;
 }
.children_form label{
    text-align: left;
    color: #718096;
    font-size: 14px;
}
.children_form input{
    padding-left: 12px;
    padding-top: 15px;
    padding-right: 16px;
    padding-bottom: 15px;
    border-radius: 15px;
    border: 1px solid #20222433;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
    background: #FAFAFA;
    outline: none;
    color: #4A5568;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
}
.children_graph input{
    padding-left: 12px;
    padding-top: 10px;
    padding-right: 11px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #20222433;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
    background:#e8ebf7;
    outline: none;
    color:#2b3034;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
}
.input_faq{
    padding-left: 12px;
    padding-top: 15px;
    padding-right: 16px;
    padding-bottom: 15px;
    border-radius: 15px;
    border: 1px solid #20222433;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
    background: #FAFAFA;
    outline: none;
    color: #4A5568;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    height: 150px;
    overflow-y: auto;
    resize: none;  
}
.input_juridiction{
    white-space: nowrap;       
    overflow: hidden;            
    text-overflow: ellipsis;
}

.children_form input::placeholder{
   font-size: 14px !important;
   font-weight: 500;
}

.down_form{
    position: absolute;
    bottom:18px;
    right: 20px;
}
.down_form_graph{
    position: absolute;
    bottom:15px;
    right: 20px;
}
.parent_textarea{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.parent_textarea label{
    text-align: left;
    color: #718096;
    font-size: 14px;
}
.parent_textarea textarea{
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    border: 1px solid #20222433;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
    background: #FAFAFA;
    outline: none;
    color: #4A5568;
    font-size: 15px;
    cursor: pointer;
    min-height: 100px;
    resize: none;
    font-weight: 500;
}
.parent_textarea textarea::placeholder{
    font-size: 14px !important;
   font-weight: 500;
}
.parent_button_tribunal{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.button_tribunal_judiciaire2{
    color:white;
    background: #FFB67C;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    width:50%;
}
.button_tribunal_judiciaire1{
    color:#000000;
    background: #F9F9FB;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    font-weight:500;
    width:50%;
}
.button_tribunal_judiciaire11{
    color:#000000;
    background: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    font-weight:500;
    width:50%;
}
.button_tribunal_judiciaire1:hover{
    background:#FFB67C;
    color: white;
    transition: 0.3s ease-in-out;
}
.button_tribunal{
    color:#fff;
    background:#FFB67C;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    font-weight:500;
    padding-left: 35px;
    padding-right: 35px;
}
.button_tribunal_black{
    color:#fff;
    background:black;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    font-weight:500;
    padding-left: 35px;
    padding-right: 35px;
}
.no-countries{
    color: #EA0234 !important;
    font-weight: 550 !important;
  
  }
  .optionCountryCity{
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translate3d(0,64px,0);
    border: 1px solid #EAECF0;
    z-index: 10000;
    max-height:200px !important;
    overflow-y: auto;
    background: #667085;
    position: absolute;
    top:15px;
    box-shadow: 0px 2px 0px 0px #E7EBEE33 inset;
    background: #FAFAFA;
    /* background: white; */
  }
  .countriesCity{
    display: flex;
    flex-direction: column;
    gap:2px;
    border-bottom: 1px solid #EAECF0;
  }
  .countriesCity_span{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
    text-align: left;
     font-size: 15px;
     transition: 1s;
     cursor: pointer;
     color: #718096;
     font-weight: 600;

  }
  .AllcountriesCity{
    display:flex;
    flex-direction:column;
  }
  .AllcountriesCity_span{
    padding-top:7px;
    padding-bottom: 7px;
    padding-left: 15px;
    text-align: left;
     font-size: 13px;
     transition: 1s;
     cursor: pointer;
     color:#718096 ;
  }
  .AllcountriesCity_span:hover{
    background:grey;
    color: white;
 }