/*Snackbar style*/
#snackbar {
    position: fixed;
    left: 15px;
    bottom: 15px !important;
    /* background:#212121; */
    background: #E94E1B;
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    /* border-left: 4px solid var(--color-primary); */
    transform: scale3d(0, 0, 0);
    font-size: 0.9rem;
    z-index: 9999999999;
    transition: transform ease 0.3s !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    contain: layout;
    display: flex;
    align-items: center;
    gap: 5px;
    min-width:300px;
  }
  #snackbarImg {
    margin-right: 3px;
    vertical-align: top !important;
    display: inline-block;
    width: 16px;
    height: 16px;
     transform: translate3d(0,-1px,0); 
  }
  /* #actionSnackbar {
    text-align: right;
    color: #D73030;
    margin-left: 50px;
    font-size: 0.9rem;
    cursor: pointer;
    display: inline-block !important;
    text-transform: uppercase;
  } */