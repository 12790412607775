/*  
  
/* Container pour la plage de dates */
.date-range-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 37.5em!important; 
    width: fit-content;
    transform: translate3d(1em,-1em,0);
  }
  
  /* Container des inputs de date */
  .input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f5f5f5;
    padding: 10px;
    margin-bottom: 10px;
    width: fit-content;
  }
  
  /* Style des inputs de date */
  .date-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 200px;
    font-size: 1em;
    background-color:#f5f5f5;
    text-align: center;
    box-shadow: none;
    outline: none;
    position: relative;
  }
  
  /* Icones de suppression */
   .clear-btn {
    background: none;
    border: none;
    font-size: 1em;
    color:white;
    padding: 1px !important;
      padding-left: 4px !important;
    padding-right: 4px !important;  
    border-radius: 100% !important;
    cursor: pointer;
    background:gray !important;
    transform: translate3d(-1.70em,0,0);
  } 
  
  /* Style de base du calendrier */
  .react-datepicker {
    font-family: Arial, sans-serif;
    background: #f5f5f5;
    color: black;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display:flex;
    gap: 5px;
    position: relative;
    /* width:fit-content; */
  }
  
  /* Enlever le triangle */
  .react-datepicker__triangle {
    display: none;
  }
  
  /* Header du calendrier */
  .react-datepicker__header {
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    padding-top: 10px;
    text-align: center;
  }
  
  /* Style des jours */
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2rem;
    line-height: 2.5rem;
    margin: 0.166rem;
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
  
  /* Style des jours sélectionnés */
  .react-datepicker__day--selected,
  .react-datepicker__day--in-range {
    background-color: #FFB67C !important;
    color: white !important;
    border-radius: 0.3rem;
  }
  
  /* Style lors du survol des jours */
  .react-datepicker__day:hover {
    background-color: #aeaeae !important;
  }
  
  /* Style des jours dans la plage */
  .react-datepicker__day--in-range:hover {
    background-color: #FFB67C !important;
  }
  
   
  