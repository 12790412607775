.header_form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.title_form{
    color:#202224;
    font-size: 16px;
    font-weight: 600;
}