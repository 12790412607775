.parent_update_categorie{
    box-shadow: 6px 6px 54px 0px #0000000D;
}
.update_categorie{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background: #FCFCFD;
    border-bottom: 1px solid #EAECF0;
    align-items: center;

}
.update_sous_categorie_text{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #EAECF0;
    align-items: center;
}
.contentEditable{
    color: #667085;
        border:none;
        outline: none;
        font-size: 13px;
        font-weight: 500;
}
.remove_categories{
    object-fit: cover;
    cursor: pointer;
}

.sous_categorie{
    color: #718096;
    font-size: 14px;
    font-weight: 500;
}
.update_sous_categorie{
    color: #718096;
    font-size: 13px;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border: 0.6px solid #CDCECE;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
}
.update_sous_categorie:hover{
   background: black;
   color: white;
   transition: background 0.3s ease-in-out;
}