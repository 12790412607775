
.left {
    position: absolute;
    left: 0;
    background:#FFFFFF;
    color: white;
    width: 17%;
    height: 100vh;
    position: fixed; 
    display: flex;
    flex-direction: column;
   gap: 12px;
}
.left_logos{
    padding-top: 10px;
    object-fit: cover;
}
.left_navigation{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.left_navigation_top{
    display: flex;
    flex-direction:column;
    gap: 10px;
}
.left_navigation_bottom{
    display: flex;
    flex-direction:column;
    gap: 10px;
}
.accueil_page{
    color:#808080;
    font-size: 15px;
}
.left_navigation_top_child2{
    width: 100%;
    border-radius: 15px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    display: flex;
    gap: 20px;
    background:#FFB67C;
    align-items: center; 
    text-decoration: none; 
    color: white; 
}
.left_navigation_top_child2_black{
    width: 100%;
    border-radius: 15px !important;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    color: black !important;
    cursor: pointer;
    display:flex !important;
    
}
.parent_biblio{
    display: flex !important;
    align-items: center !important;
    gap:7px !important;
}
.nav_link_home{
    text-decoration: none;
}
.nav_link_home_black{
    text-decoration: none;
    color:black;
}
.dasboard{
    font-size: 16px;
    text-decoration: none !important;
    font-weight: 550;
    color: #3E3E3E; 
}
.white{
    font-size: 16px !important;
    text-decoration: none !important;
    font-weight: 550;
    color:"white" !important;
}
.left_navigation_bottom_child2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-bottom:15px;
    cursor: pointer;
}


.left_navigation_bottom_child2__child1{
    display: flex;
    gap: 5px;
    align-items: center;
}
.left_navigation_bottom_span{
    font-size: 16px;
     color: #3E3E3E; 
     font-weight: 550;
}
.left_navigation_bottom_span_nav_link{
    font-size: 16px !important;
     color: #3E3E3E !important; 
     font-weight: 550;
     text-decoration: none !important;
}
.left_navigation_bottom_span_nav{
    font-size: 16px;
    text-decoration:none !important;
    color:#FFB67C !important; 
     font-weight: 550;
}
.open_pages{
    margin: auto;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
}


.open_pages span{
    font-size: 16px;
    color: #808080;
}
.open_pages span:hover{
    color:#FFB67C ;
    transition: color 0.3s ease-in-out;
}
.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-out;
}
.nav_link{
    text-decoration:none !important;
    color:#FFB67C !important; 
    font-size: 15px !important;
}
.nav_link_none{
    text-decoration:none;
    color:#808080;
    font-size: 15px !important;
}