.button{
    background: #F9F9FB;
    color: #000000;
    padding-top: 13px;
    padding-bottom: 13px;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: 0px 0px 4px 0px #00000026;
     width:fit-content; 
    text-align: center;
    padding-right:55px;
    padding-left: 55px;
    cursor: pointer;
}

.button_span2{
    font-size: 15px;
    text-align: center;
    font-weight: 550;
}
.button:hover{
    background: #FFB67C;
    color: white;
    transition:background 1s ease-in-out;
}